<i18n>
{
  "en": {
    "browser_title": "Actor List",
    "actor_list": "Actor List"
  },
  "ja": {
    "browser_title": "AV男優一覧",
    "actor_list": "AV男優一覧"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />

    <div class="contents">
      <div class="heading">
        <h1>{{ $t('actor_list') }}</h1>
      </div>

      <!-- japanese tabs -->
      <vue-tabs v-if="locale == 'ja'" type="pills" class="tab-scroll" id="actor--tab-character" v-model="activeTab" @tab-change="handleTabChange">
        <v-tab title="あ行">
          <actors-list character="あ" character-sub="あ" />
          <actors-list character="あ" character-sub="い" />
          <actors-list character="あ" character-sub="う" />
          <actors-list character="あ" character-sub="え" />
          <actors-list character="あ" character-sub="お" />
        </v-tab>
        <v-tab title="か行">
          <actors-list character="か" character-sub="か" />
          <actors-list character="か" character-sub="き" />
          <actors-list character="か" character-sub="く" />
          <actors-list character="か" character-sub="け" />
          <actors-list character="か" character-sub="こ" />
        </v-tab>
        <v-tab title="さ行">
          <actors-list character="さ" character-sub="さ" />
          <actors-list character="さ" character-sub="し" />
          <actors-list character="さ" character-sub="す" />
          <actors-list character="さ" character-sub="せ" />
          <actors-list character="さ" character-sub="そ" />
        </v-tab>
        <v-tab title="た行">
          <actors-list character="た" character-sub="た" />
          <actors-list character="た" character-sub="ち" />
          <actors-list character="た" character-sub="つ" />
          <actors-list character="た" character-sub="て" />
          <actors-list character="た" character-sub="と" />
        </v-tab>
        <v-tab title="な行">
          <actors-list character="な" character-sub="な" />
          <actors-list character="な" character-sub="に" />
          <actors-list character="な" character-sub="の" />
        </v-tab>
        <v-tab title="は行">
          <actors-list character="は" character-sub="は" />
          <actors-list character="は" character-sub="ひ" />
          <actors-list character="は" character-sub="ふ" />
          <actors-list character="は" character-sub="ほ" />
        </v-tab>
        <v-tab title="ま行">
          <actors-list character="ま" character-sub="ま" />
          <actors-list character="ま" character-sub="み" />
          <actors-list character="ま" character-sub="む" />
          <actors-list character="ま" character-sub="め" />
          <actors-list character="ま" character-sub="も" />
        </v-tab>
        <v-tab title="や行">
          <actors-list character="や" character-sub="や" />
          <actors-list character="や" character-sub="ゆ" />
          <actors-list character="や" character-sub="よ" />
        </v-tab>
        <v-tab title="ら行">
          <actors-list character="ら" character-sub="ら" />
          <actors-list character="ら" character-sub="り" />
          <actors-list character="ら" character-sub="る" />
          <actors-list character="ら" character-sub="れ" />
        </v-tab>
        <v-tab title="わ行">
          <actors-list character="わ" character-sub="わ" />
        </v-tab>
      </vue-tabs>

      <!-- english tabs -->
      <vue-tabs v-if="locale == 'en'" type="pills" class="tab-scroll" v-model="activeTab" @tab-change="handleTabChange">
        <v-tab v-for="(item, index) in actorEnList" :key="index" :title="index">
          <actors-list :character="index" :character-sub="index" />
        </v-tab>
      </vue-tabs>
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */
import { VueTabs, VTab } from 'vue-nav-tabs';
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import ActorsList from '@/components/actor/actorsList.vue';

export default {
  components: {
    VueTabs,
    VTab,
    'vue-headful': vueHeadful,
    'actors-list': ActorsList,
  },
  data() {
    return {
      dynamicTitle: '',
      actorEnList: [],
      activeTab: (this.$route.hash) ? decodeURIComponent(this.$route.hash).substr(1) : 'あ行',
    };
  },
  async created() {
    const bfAPI = new BifrostAPI();
    this.actorEnList = await bfAPI.getActorsEnList();
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    if (this.$route.hash) {
      this.activeTab = decodeURIComponent(this.$route.hash).substr(1); // remove the hash character
    }

    // one-time emit to get the contents of the 1st tab
    EventBus.$emit('actor:tab:active', this.activeTab);
  },
  methods: {
    handleTabChange(tabIndex, newTab) {
      EventBus.$emit('actor:tab:active', newTab.title);

      // doing a sneaky thing here and using the history API to add a hash anchor instead of using
      // $router.replace() since that seems to trigger a route change, which is what we don't want.
      // btw, using replaceState instead of pushState so that the tab changes don't annoyingly get
      // saved in the browser history
      window.history.replaceState({}, null, `${this.$route.path}#${encodeURIComponent(newTab.title)}`);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_actress.scss';
</style>
