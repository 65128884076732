<i18n>
{
  "en": {
    "actor_header": "",
    "people": ""
  },
  "ja": {
    "actor_header": "から始まるAV男優",
    "people": "人"
  }
}
</i18n>

<template>
<div class="section-wide" v-if="actorsList.length">
  <div v-if="locale == 'ja'" class="heading">
    <h2 v-if="actorsList">「{{ characterSub }}」{{ $t('actor_header') }} <span class="search-count">({{ actorsList.length }}{{ $t('people') }})</span></h2>
  </div>
  <div class="actress">
    <div class="flex-grid">
      <div class="grid-item" v-for="(item, index) in actorsList" :key="item.id">
        <router-link :to="`/search/?am=${item.id}`" class="entry">
          <div class="entry-media">
            <div class="media-thum">
              <!-- 100 x 100 would be preferred, but using 80x80 because the 50x50 legacy images are potato when upscaled -->
              <img :src="`/dyn/dla/images/actors/portraits/${item.id}/1pon__@200.jpg`" @error="imgPlaceholder(item.image_url, item.id, index)" ref="actorImg" class="media-thum-image" loading="lazy" width="80" height="80" v-sfw />
            </div>
          </div>
          <div class="entry-meta">
            <div class="meta-name">{{ item.name }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  props: {
    character: {
      type: String,
    },
    characterSub: {
      type: String,
    },
  },

  data() {
    return {
      actorsList: [],
      triedLegacyImg: {},
    };
  },

  async created() {
    const bfAPI = new BifrostAPI();
    let actors = [];

    // set listener for emit event from the parent tab component
    EventBus.$on('actor:tab:active', async (title) => {
      // get list if the title matches the 1st character and hasn't been fetched yet
      if (title.substr(0, 1) === this.character && this.actorsList.length === 0) {
        if (this.locale === 'ja') {
          actors = await bfAPI.getActorsList();
        } else if (this.locale === 'en') {
          actors = await bfAPI.getActorsEnList();
        }
        if (Object.prototype.hasOwnProperty.call(actors[this.character], this.characterSub)) {
          this.actorsList = actors[this.character][this.characterSub];
        }
      }
    });
  },

  methods: {
    imgPlaceholder(url, actorID, index) {
      // first we try loading the actress image from DL admin. if that fails, we load the legacy
      // thumbnails. if that fails, use a static placeholder image
      if (this.triedLegacyImg[actorID]) {
        this.$refs.actorImg[index].src = '/img/common/actress_placeholder.png';
      } else {
        this.$refs.actorImg[index].src = url;
        this.$set(this.triedLegacyImg, actorID, true);
      }
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
